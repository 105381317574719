/*
* @Author: 曹俊杰
* @Date: 2022-02-22 18:21:16
* @Module: 添加电子文书
*/
<template>
  <div class="electronicDocuments">
    <e-header :step="1" @next="submitForm" @close="close" />
    <div class="content">
      <hdq-section title="合同基本信息" moreText="" />
      <div style="padding:0 24px;width: 470px;">
        <el-upload accept=".pdf,.docx,.doc,.wps" :limit="1" :headers="{
          Authorization: 'Bearer ' + user.token
        }" :on-success="onSuccess" action="/api/file" :on-preview="onPreview" :on-exceed="onExceed" :before-upload="beforeUpload" :before-remove="beforeRemove" :on-error="onError" multiple :file-list="fileList">

          <div class="upload">
            <i class="el-icon-upload2"></i>
            点击上传文档
          </div>
        </el-upload>

        <el-form label-suffix="：" :model="form" ref="form" style="margin-top:15px" @submit.native.prevent>
          <el-form-item label="合同名称" prop="subject" :rules="{ required: true, message: '请输入合同名称' }">
            <el-input v-model.trim="form.subject" size="mini" placeholder="请输入合同名称" style="width:322px"></el-input>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <div class="content" style="margin-top:16px;">
      <hdq-section title="设置签署方" moreText="" />
      <div class="flow">
        <div class="row" v-if="signersList.length">
          <div class="col1">签署</div>
          <div class="col2">
            <span style="margin-left:10%;">参与方信息</span>
          </div>
        </div>
        <div v-for="(item, index) in signersList" :key="item.id">
          <el-form :model="item" :ref="'form' + item.id">

            <div class="row PERSON" v-if="item.type === 'PERSON'">
              <div class="col1">{{ index + 1 }}</div>
              <div class="col2 ">
                <div class="l">个人</div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorName" :rules="{ required: true, message: '个人姓名不能为空' }">
                    <el-autocomplete popper-class="autocomplete" style="width:100%" value-key="operatorName" v-model.trim="item.operatorName" size="mini" :fetch-suggestions="PERSONSearch" placeholder="个人姓名" trigger-on-focus
                      @select="res => handleSelect(res, index)">
                    </el-autocomplete>
                  </el-form-item>
                </div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorPhone" :rules="phoneRules">
                    <el-input v-model.trim="item.operatorPhone" size="mini" placeholder="个人手机号"></el-input>
                  </el-form-item>
                </div>
                <div class="PERSON-item">
                  <el-form-item prop="operatorIdType" :rules="operatorIdType">
                    <el-select v-model="item.operatorIdType" size="mini" placeholder="选择您的证件类型">
                      <el-option v-for="item in idType" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>

                <div class="PERSON-item">
                  <el-form-item prop="operatorIdNumber" :rules="idCardRules">
                    <el-input v-model.trim="item.operatorIdNumber" size="mini" placeholder="证件号"></el-input>
                  </el-form-item>
                </div>
                <div class="el-icon-delete r" @click="() => remove(item)"></div>
              </div>
            </div>

            <div class="row ORG" v-if="item.type === 'ORG'">
              <div class="col1">{{ index + 1 }}</div>
              <div class="col2 ">
                <div class="l">企业</div>
                <div class="c">
                  <div class="t">
                    <div class="ORG-item">
                      <el-form-item prop="name" :rules="{ required: true, message: '企业名称不能为空' }">
                        <el-autocomplete :disabled="disabled" popper-class="autocomplete" style="width:100%" v-model="item.name" size="mini" :fetch-suggestions="ORGSearch" placeholder="企业名称" trigger-on-focus
                          @select="res => handleSelect(res, index)">
                          <template slot-scope="{ item }">
                            <div class="name">{{ item.name }}</div>
                            <span class="addr">{{ item.operatorName }}</span>
                          </template>
                        </el-autocomplete>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="idNumber" :rules="{ required: true, message: '社会信用代码不能为空' }">
                        <el-input :disabled="disabled" v-model="item.idNumber" size="mini" placeholder="社会信用代码"></el-input>
                      </el-form-item>
                    </div>

                  </div>
                  <div class="b">
                    <div class="ORG-item">
                      <el-form-item prop="operatorName" :rules="{ required: true, message: '合同操作人不能为空' }" error="选择谁，则谁负责扫码签约">
                        <el-input v-model="item.operatorName" size="mini" placeholder="签署人姓名"></el-input>

                        <!-- <el-select @change="(res, index) => ORGchange(res, item)" v-model="item.operatorName"
                          size="mini" style="width:100%" placeholder="请选择合同操作人">
                          <el-option v-for="item in accountColleagueList" :key="item.uuid" :label="item.name"
                            :value="item.uuid">
                          </el-option>
                        </el-select> -->
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="operatorPhone" :rules="{ required: true, message: '合同操作人手机号不能为空' }">
                        <el-input :disabled="disabled" v-model="item.operatorPhone" size="mini" placeholder="合同操作人手机号"></el-input>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="operatorIdType" :rules="operatorIdType">
                        <el-select v-model.trim="item.operatorIdType" size="mini" placeholder="选择您的证件类型">
                          <el-option v-for="item in idType" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="ORG-item">
                      <el-form-item prop="operatorIdNumber" :rules="{ required: true, message: '合同操作人证件号不能为空' }">
                        <el-input :disabled="disabled" v-model="item.operatorIdNumber" size="mini" placeholder="合同操作人证件号"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="el-icon-delete r" @click="() => remove(item)"></div>
              </div>
            </div>
          </el-form>
        </div>
        <div style="padding-left:72px;">
          <el-button type="primary" plain size="small" @click="() => add('ORG')">添加企业</el-button>
          <el-button type="warning" plain size="small" @click="() => add('PERSON')">添加个人</el-button>
        </div>
      </div>
    </div>
    <mElectronicFooter hidBack @next="submitForm" :disabledNext="disabledNext" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import eHeader from "./components/eHeader.vue"
import mElectronicFooter from "@/components/mElectronicFooter.vue"
import userinfo from "@/mixins/userinfo.js"
import selectData from "@/mixins/selectData.js"
let isback = false
export default {
  mixins: [userinfo, selectData],
  components: {
    eHeader,
    mElectronicFooter
  },
  data() {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      phoneRules: [{ required: true, message: '个人手机号不能为空' }, { validator: phoneValidator, trigger: 'blur' }],
      idCardRules: [{ required: true, message: '证件号不能为空', trigger: 'blur' }],
      operatorIdType: [{ required: true, message: '证件类型不能为空', trigger: 'blur' }],
      fileList: [],
      form: {
        subject: '',
        files: []
      },
      signersList: [

      ],
      accountColleagueList: [],
      disabled: false,
    };
  },
  mounted() {

    this.getAccountColleagueList(data => {
      console.log(data)
      // const item = data[0]
      // this.signersList.push({
      //   id: this.$utils.guid(),
      //   type: 'ORG',//PERSON 个人,ORG 企业
      //   name: this.companyObj.name,
      //   idNumber: this.companyObj.idNumber,
      //   operatorName: '',
      //   operatorPhone: '',
      //   operatorIdNumber: ''
      // })
    })
    this.init()
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      personalSearch: state => state.electronic.personalSearch,
      enterpriseSearch: state => state.electronic.enterpriseSearch,
      electronicData: state => state.electronic.electronicData,
    }),
    disabledNext() {
      if (this.signersList.length < 2) {
        return true
      }
      if (!this.form.subject) {
        return true
      }
      if (!this.form.files.length) {
        return true
      }
      return false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log('组件内前置守卫:', to, from, this);
      if (from.name === 'contractInsurance') {
        isback = true;
      }
    });
  },
  methods: {
    // 恢复数据
    regain() {
      const error = !!this.$route.query.error
      const { signers, subject, files } = this.electronicData;
      this.form.subject = subject;
    
      this.signersList = signers.map(res=>{
        if(res.operatorIdType){
          return res
        }
        return {
          ...res,
          operatorIdType:'CRED_PSN_CH_IDCARD'
        }
      });
      if(error){
        return
      }
      //  如果是错误返回，不恢复数据
      this.form.files = files;
      this.fileList = files.map(res => ({ name: res.fileName }));
    },
    init() {
      const { signers, subject, files } = this.electronicData;
      if (!subject && signers.length == 0 && files.length == 0) {
        return
      }
      //   如果是从上个页面返回回来的，直接恢复数据
      if (isback) {
        this.regain()
        return
      }


      this.$alert('有上次未完成的合同，是否恢复？', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: action => {
          // 如果点击确定  恢复数据，否则清空数据
          if (action == 'confirm') {
            this.regain()
            return
          }
          this.$store.dispatch('electronic/reset')
          this.$store.dispatch('insuranceSelect/insuranceSelectClear')
        }
      })
    },
    clearFile() {
      console.log(111)
      this.form.files = [];
      this.fileList = [];
    },
    close() {
      this.$router.replace('/home');
    },
    ORGSearch(queryString, cb) {
      var restaurants = this.enterpriseSearch;
      var results = queryString ? restaurants.filter(item => item.name.includes(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item, index) {
      const id = this.signersList[index].id
      // const id = this.$utils.guid()
      let list = [...this.signersList]
      list[index] = { ...item, id }
      this.signersList = list
    },
    PERSONSearch(queryString, cb) {
      var restaurants = this.personalSearch;
      var results = queryString ? restaurants.filter(item => item.operatorName.includes(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    submitForm() {
      if (this.signersList.length < 2) {
        this.$message({
          message: '至少添加两个任务参与人',
          type: 'error'
        })
        return
      }

      const arr = this.signersList.map(res => {
        if (res.type == "ORG" && (res.operatorName.length > 10)) {
          res.operatorName = this.accountColleagueList[this.accountColleagueList.findIndex(r => r.uuid == res.operatorName)].name;
        }
        return this.$refs['form' + res.id][0].validate();
      })
      arr.push(this.$refs.form.validate())
      Promise.all(arr).then((values) => {
        if (values.findIndex(target => target === false) == -1) {
          console.log(this.form.files)
          if (this.form.files.length) {
            // this.$router.push({ name: 'electronicDocumentsPDF' })
            this.$router.push({ name: 'contractInsurance' })
            this.$store.commit('electronic/setElectronic', {
              subject: this.form.subject,
              files: this.form.files,
              signers: this.signersList,
            })
            this.$store.commit('electronic/saveHistory', this.signersList)
          } else {
            this.$message({
              type: 'error',
              message: '请上传合同文件'
            })
          }
        } else {
          alert('error!');
        }
      });
    },
    //  添加数据
    add(res) {
      const id = this.$utils.guid()


      //添加个人
      if (res === 'PERSON') {
        // const PERSON_my = {
        //   id,
        //   type: res,//PERSON 个人,ORG 企业
        //   operatorName: this.user.name,
        //   operatorPhone: this.user.phone,
        //   operatorIdNumber: this.user.idNumber,
        // }
        const PERSON_def = {
          id,
          type: res,//PERSON 个人,ORG 企业
          operatorName: '',
          operatorPhone: '',
          operatorIdNumber: '',
          operatorIdType: 'CRED_PSN_CH_IDCARD'
        }

        //  判断有没有添加过我的数据，添加过就添加空数据
        // const index = this.signersList.findIndex(res => (res.operatorIdNumber == this.user.idNumber) && (res.type == 'PERSON'))
        // if (index > -1) {
        //   this.signersList.push(PERSON_def)
        // } else {
        //   this.signersList.push(PERSON_my)
        // }
        this.signersList.push(PERSON_def)
        // 添加企业
      } else if (res === 'ORG') {
        const ORG_my = {
          id,
          type: res,//PERSON 个人,ORG 企业
          name: this.companyObj.name,
          idNumber: this.companyObj.idNumber,
          operatorName: this.user.name,
          operatorPhone: this.user.phone,
          operatorIdNumber: this.user.idNumber,
          operatorIdType: 'CRED_PSN_CH_IDCARD'
        }
        const ORG_def = {
          id,
          type: res,//PERSON 个人,ORG 企业
          idNumber: '',
          name: '',
          operatorName: '',
          operatorPhone: '',
          operatorIdNumber: '',
          operatorIdType: 'CRED_PSN_CH_IDCARD'
        }
        const index = this.signersList.findIndex(res => (res.operatorIdNumber == this.user.idNumber) && (res.type == 'ORG'))
        if (index > -1) {
          this.signersList.push(ORG_def)
        } else {
          this.signersList.push(ORG_my)
        }
      }
    },
    //文件超出个数限制时的钩子
    onExceed(files, fileList) {
      this.$message({
        message: '只可同时签订一份合同',
        type: 'error'
      })
    },
    //文件上传成功时的钩子
    onSuccess(response) {
      console.log(response)
      const statusData = [
        '文件未上传',
        '文件上传中',
        '文件上传已完成',
        '文件上传失败',
        '文件等待转pdf',
        '文件已转换pdf ',
        '加水印中',
        '加水印完毕',
        '文件转换中',
        '文件转换失败'
      ]
      const { status, name, fileId, downloadUrl } = response;
      if ([1, 2, 4, 5, 6, 7, 8].includes(status)) {
        this.form.files = [{
          fileId: fileId,
          fileName: name
        }]
        this.$store.commit('electronic/setElectronic', {
          file: downloadUrl
        })
        this.$message({
          type: 'success',
          message: statusData[status]
        })
      } else {

        this.$message({
          type: 'error',
          message: statusData[status]
        })
      }
    },
    remove({ id }) {
      const i = this.signersList.findIndex(res => res.id === id)
      if (i > -1) {
        this.signersList.splice(i, 1)
      }
    },
    // 点击已上传成功文件
    onPreview(file) {
    },
    async beforeRemove(file) {
      await this.$confirm(`确定移除 ${file.name}？`)
      this.clearFile()
      return true;
    },
    onError() {
      console.log('onError')
    },
    // 文件上传前
    beforeUpload({ size }) {
      const MB = 40
      const maxSize = MB * 1024 * 1024
      if (size > maxSize) {
        this.$message({
          message: `文件大小不能超过${MB}MB`,
          type: 'error'
        })
        return false
      }
    },
    async getAccountColleagueList(callback) {
      const { status, data } = await this.$api.getAccountColleagueList({ withSealAuth: true })
      if (status === 200) {
        this.accountColleagueList = data;
        callback && callback(data)
      }
    },
    ORGchange(res, item) {
      const user = this.accountColleagueList[this.accountColleagueList.findIndex(r => r.uuid == res)];
      item.operatorIdNumber = user.idNumber;
      item.operatorPhone = user.phone;
    }
  }
};
</script>
<style lang='scss' scoped>
.electronicDocuments {
  .content {
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  }

  &/deep/.el-card {
    &__body {
      padding: 0px;
    }
  }
}

.autocomplete {
  li {
    line-height: normal;
    padding: 4px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

.upload {
  width: 416px;
  background: #f5f9ff;
  border-radius: 4px;
  border: 1px dashed #66a1ff;

  font-size: 14px;
  color: #0062ff;
  line-height: 64px;
}

.flow {
  padding: 0 24px;

  .row {
    display: flex;
    font-size: 12px;
    color: #666666;

    .col1 {
      width: 60px;
      background: #f7f8f9;
      border-radius: 4px;
      // line-height: 40px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .col2 {
      background: #f7f8f9;
      border-radius: 4px;
      line-height: 40px;
      flex: 1;
      margin-left: 12px;
      margin-bottom: 12px;
      align-items: center;
    }

    .l {
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .r {
      width: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .PERSON {
    .col1 {
    }

    .col2 {
      display: flex;
      background: #fff;
      box-shadow: 0px 4px 6px 0px #f5f9ff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      height: 100px;

      .l {
        color: #ef7d00;
      }

      .PERSON-item {
        padding-top: 22px;
        width: calc((100% - 66px - 112px - (20px * 3)) / 4);
        margin-right: 20px;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .r {
        font-size: 16px;
        color: #999999;

        &:hover {
          color: #e05353;
        }
      }
    }
  }

  .ORG {
    .col1 {
    }

    .col2 {
      display: flex;
      background: #fff;
      box-shadow: 0px 4px 6px 0px #f5f9ff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;

      .l {
        color: #0062ff;
      }

      .c {
        width: calc(100% - 66px - 112px);
        padding-top: 27px;
        padding-bottom: 5px;

        .b {
          width: 100%;
          display: flex;

          .ORG-item {
            // padding-top: 22px;
            width: calc((100% - (20px * 3)) / 4);
            margin-right: 20px;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }

        .t {
          display: flex;

          // margin-top: 12px;
          .ORG-item {
            // padding-top: 22px;
            width: calc((100% - (20px * 2)) / 3);
            margin-right: 20px;

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:last-child {
              width: calc(((100% - (20px * 2)) / 3) * 2 + 20px);
            }
          }
        }
      }

      .r {
        font-size: 16px;
        color: #999999;

        &:hover {
          color: #e05353;
        }
      }
    }
  }
}
</style>